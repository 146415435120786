import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useFetchGraphQLElementSet } from 'client/app/api/ElementsApi';
import { GraphQLWorkflow } from 'client/app/api/gql/utils';
import { useUpdateWorkflow } from 'client/app/apps/protocols/api/ProtocolsAPI';
import { useUpdateEntity } from 'client/app/apps/protocols/lib/utils';
import { deserialiseWorkflowResponse } from 'client/app/apps/workflow-builder/lib/workflowUtils';
import { getOutputVisualisationTypeFromParameterType } from 'client/app/components/ElementPlumber/ElementOutputs/helpers';
import { ElementSetQuery } from 'client/app/gql';
import ParameterStateContextProvider from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import {
  BundleParameters,
  ElementContextMap,
  ElementInstance,
  emptyWorkflowConfig,
  Parameter,
  WorkflowConfig,
} from 'common/types/bundle';
import { ErrorCodes } from 'common/types/errorCodes';
import { getElementId, getElementParameterName, Schema } from 'common/types/schema';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

const emptySchema = () => {
  return { inputs: [], outputs: [] };
};

/** SchemaParameter is a SchemaInput transformed with workflow context */
type SchemaParameter = {
  id: string;
  element?: ElementInstance;
  parameter?: Parameter;
  defaultValue?: any;
};

type WorkflowContextType = {
  id: WorkflowId;
  config: WorkflowConfig;
  parameters: BundleParameters;
  loading: boolean;
  conflictDialog: JSX.Element | null;
  /** update workflow state parameter input */
  updateInput: (schemaInputId: string, value: any) => void;
  /** update workflow state parameter output to display */
  updateOutput: (schemaInputId: string) => void;
  /** update workflow state with context of element outputs from being ran */
  updateElementContext: (context: ElementContext) => void;
  /** triggers updateWorkflow mutation with provided params */
  handleUpdateSchema: (update: Schema) => void;
  getSchemaParameter: (schemaInputId: string) => SchemaParameter;
  getElementInstance: (elementInstanceId: string) => ElementInstance | undefined;
};

export const WorkflowContext = createContext<WorkflowContextType>({
  id: '' as WorkflowId,
  config: emptyWorkflowConfig(),
  parameters: {},
  loading: false,
  conflictDialog: null,
  updateInput: () => {},
  updateOutput: () => {},
  updateElementContext: () => {},
  handleUpdateSchema: () => {},
  getSchemaParameter: () => {
    return { id: '' };
  },
  getElementInstance: () => undefined,
});

export const useWorkflowContext = () => {
  const context = useContext(WorkflowContext);

  if (context === undefined) {
    throw new Error('useWorkflowContext must be used within a WorkflowProvider');
  }

  return context;
};

type WorkflowProviderProps = {
  workflow: GraphQLWorkflow;
  elementContext: ElementContext;
} & PropsWithChildren;

type ElementContext = {
  elementContextMap: ElementContextMap | null;
  elementContextError: CoreErrorBlob | null;
};

export const WorkflowProvider: FC<WorkflowProviderProps> = ({
  elementContext,
  workflow,
  children,
}) => {
  const dispatch = useWorkflowBuilderDispatch();
  const snackbar = useSnackbarManager();
  const fetchGraphQLElementSet = useFetchGraphQLElementSet();
  const [elementSet, setElementSet] = useState<ElementSetQuery['elementSet']>();
  const { handleUpdateWorkflow, loading: updateLoading } = useUpdateWorkflow();
  const [schema, setSchema] = useState(workflow.workflow.Schema || emptySchema());
  const loading = elementSet === undefined || updateLoading;

  // elementSet should only be fetched once since since we do not allow
  // uploading workflows or changing branches in protocols. Moreover the query
  // is normally quite expensive
  useEffect(() => {
    (async () => {
      try {
        setElementSet(await fetchGraphQLElementSet(workflow.id));
      } catch (err) {
        snackbar.showError(err);
      }
    })();
  }, [dispatch, fetchGraphQLElementSet, snackbar, workflow.id]);

  // on the other hand, workflow state may change depending on how the provider
  // is used and is cheap to update
  useEffect(() => {
    if (loading) return;

    const { workflowState, errors } = deserialiseWorkflowResponse(workflow, elementSet);
    if (errors.length > 0) {
      snackbar.showError(errors.join(' '));
    }
    dispatch({
      type: 'resetToWorkflow',
      payload: workflowState,
    });
    if (elementContext.elementContextMap) {
      dispatch({
        type: 'updateElementsWithContexts',
        payload: elementContext.elementContextMap,
      });
    }
    if (elementContext.elementContextError) {
      dispatch({
        type: 'setElementContextError',
        payload: elementContext.elementContextError,
      });
    }
  }, [
    dispatch,
    elementContext.elementContextError,
    elementContext.elementContextMap,
    elementSet,
    loading,
    snackbar,
    workflow,
  ]);

  const { conflictDialog, setUpdateRequired } = useUpdateEntity({
    entityType: 'workflow',
    editVersion: workflow.version,
    conflictCode: ErrorCodes.WORKFLOW_EDIT_CONFLICT,
    handleUpdate: useCallback(
      async (editVersion: number) => {
        const params = { ...workflow.workflow, Schema: schema };
        await handleUpdateWorkflow(workflow.id, editVersion, params);
      },
      [handleUpdateWorkflow, schema, workflow.id, workflow.workflow],
    ),
  });

  const {
    config,
    parameters,
    elementInstances,
    outputPreviewProps,
    InstancesConnections: connections,
  } = useWorkflowBuilderSelector(state => state);

  const schemaInputsById = useMemo(
    () => Object.fromEntries(schema.inputs?.map(input => [input.id, input]) || []),
    [schema.inputs],
  );

  const schemaOutputsById = useMemo(
    () => Object.fromEntries(schema.outputs?.map(output => [output.id, output]) || []),
    [schema.outputs],
  );

  const elementsById = useMemo(
    () => Object.fromEntries(elementInstances.map(instance => [instance.Id, instance])),
    [elementInstances],
  );

  const getSchemaParameter = useCallback(
    (schemaInputId: string): SchemaParameter => {
      const schemaInput = schemaInputsById[schemaInputId] || { path: [] };
      const { path, typeName, default: defaultValue } = schemaInput;
      const paramName = getElementParameterName(path);
      const elementId = getElementId(path);
      const element = elementId ? elementsById[elementId] : undefined;
      const parameter = paramName
        ? { name: paramName, type: typeName, description: '' }
        : undefined;
      return { id: schemaInputId, element, parameter, defaultValue };
    },
    [elementsById, schemaInputsById],
  );

  const getElementInstance = useCallback(
    (id: string) => elementsById[id],
    [elementsById],
  );

  const updateInput = useCallback(
    (schemaInputId: string, value: any) => {
      const { element, parameter } = getSchemaParameter(schemaInputId);
      if (element && parameter) {
        dispatch({
          type: 'updateParameter',
          payload: {
            instanceName: element.name,
            parameterName: parameter.name,
            value: value,
          },
        });
      }
    },
    [dispatch, getSchemaParameter],
  );

  const updateOutput = useCallback(
    (schemaOutputId: string) => {
      const schemaOutput = schemaOutputsById[schemaOutputId] || { path: [] };
      const { path, typeName } = schemaOutput;
      const paramName = getElementParameterName(path);
      const elementId = getElementId(path);
      if (elementId && paramName) {
        dispatch({
          type: 'openOutputPreview',
          payload: {
            selectedElementId: elementId,
            selectedOutputParameterName: paramName,
            outputType: getOutputVisualisationTypeFromParameterType(typeName),
            entityView: outputPreviewProps.entityView ?? 'plate', // TODO: consider encoding some state in URL-params
          },
        });
      }
    },
    [dispatch, outputPreviewProps.entityView, schemaOutputsById],
  );

  const updateElementContext = useCallback(
    (context: ElementContext) => {
      const { elementContextError, elementContextMap } = context;
      if (elementContextMap) {
        dispatch({ type: 'updateElementsWithContexts', payload: elementContextMap });
      }
      if (elementContextError) {
        dispatch({ type: 'setElementContextError', payload: elementContextError });
      }
    },
    [dispatch],
  );

  const handleUpdateSchema = useCallback(
    (update: Schema) => {
      setSchema(update);
      setUpdateRequired(true);
    },
    [setUpdateRequired],
  );

  const state = {
    id: workflow.id,
    config,
    parameters,
    loading,
    conflictDialog,
    updateInput,
    updateOutput,
    updateElementContext,
    handleUpdateSchema,
    getSchemaParameter,
    getElementInstance,
  };

  return (
    <ParameterStateContextProvider
      parameters={parameters}
      elementInstances={elementInstances}
      // must set connections as element configuration rules are dependent on
      // them even if the protocols app is not
      connections={connections}
    >
      <WorkflowContext.Provider value={state}>{children}</WorkflowContext.Provider>
    </ParameterStateContextProvider>
  );
};
