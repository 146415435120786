import { Reference, useMutation, useQuery } from '@apollo/client';

import { getResultOrThrow } from 'client/app/api/apolloClient';
import {
  COPY_PROTOCOL,
  CREATE_PROTOCOL,
  CREATE_PROTOCOL_INSTANCE,
  DELETE_PROTOCOL,
  PUBLISH_PROTOCOL,
  UPDATE_PROTOCOL,
  UPDATE_PROTOCOL_INSTANCE,
  UPDATE_PROTOCOL_WORKFLOW,
} from 'client/app/api/gql/mutations';
import { QUERY_PROTOCOL, QUERY_PROTOCOL_INSTANCE } from 'client/app/api/gql/queries';
import {
  CreateProtocolInstanceMutation,
  CreateProtocolMutation,
  DeleteProtocolMutation,
  PublishProtocolMutation,
} from 'client/app/gql';
import { experimentsRoutes, protocolsRoutes } from 'client/app/lib/nav/actions';
import { Protocol } from 'common/types/Protocol';
import { ParameterMap } from 'common/types/schema';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';

/**
 * Queries a protocol instance.
 *
 * @returns Protocol instance and loading state.
 */
export function useQueryProtocolInstance(protocolInstanceId: ProtocolInstanceId) {
  const { data, loading, error } = useQuery(QUERY_PROTOCOL_INSTANCE, {
    variables: { id: protocolInstanceId },
  });
  return { data, loading, error };
}

/**
 * Queries a protocol
 *
 * @returns Protocol and loading state.
 */
export function useQueryProtocol(protocolId: ProtocolId, version: ProtocolVersion) {
  const { data, loading, error } = useQuery(QUERY_PROTOCOL, {
    variables: { id: protocolId, version },
  });
  return { data, loading, error };
}

/**
 * Creates a new protocol from an existing workflow.
 *
 * @returns Handler for creating protocol and loading state.
 */
export function useCreateProtocol() {
  const [createProtocol, { loading }] = useMutation(CREATE_PROTOCOL);

  const handleCreateProtocol = async (
    name: string,
    workflowId: WorkflowId,
  ): Promise<CreateProtocolMutation['createProtocol']> => {
    const updateResult = await createProtocol({
      variables: {
        input: {
          name,
          workflowId,
        },
      },
    });

    return getResultOrThrow(updateResult, 'Create protocol', data => data.createProtocol);
  };

  return { handleCreateProtocol, loading };
}

/**
 * Creates a copy  of the protocol
 *
 * @returns Handler for copyingj protocol and loading state.
 */
export function useCopyProtocol() {
  const [copyProtocol, { loading }] = useMutation(COPY_PROTOCOL);

  const handleCopyProtocol = async (
    id: ProtocolId,
    version: ProtocolVersion,
  ): Promise<CreateProtocolMutation['createProtocol']> => {
    const updateResult = await copyProtocol({
      variables: {
        input: {
          id,
          version,
        },
      },
    });

    return getResultOrThrow(updateResult, 'Copy protocol', data => data.copyProtocol);
  };

  return { handleCopyProtocol, loading };
}

/**
 * Creates a new protocol instance from an existing protocol.
 *
 * @returns Handler for creating protocol instance and loading state.
 */
export function useCreateProtocolInstance() {
  const [createProtocolInstance, { loading }] = useMutation(CREATE_PROTOCOL_INSTANCE);
  const handleCreateProtocolInstance = async (
    protocolId: ProtocolId,
    protocolVersion: ProtocolVersion,
  ): Promise<CreateProtocolInstanceMutation['createProtocolInstance']> => {
    const updateResult = await createProtocolInstance({
      variables: {
        input: {
          protocolId: protocolId,
          protocolVersion: protocolVersion,
        },
      },
    });

    return getResultOrThrow(
      updateResult,
      'Create protocol instance',
      data => data.createProtocolInstance,
    );
  };

  return { handleCreateProtocolInstance, loading };
}

/**
 * Creates a new protocol from an existing workflow and navigates
 * to the editing route for that new protocol.
 *
 * @returns Handler for creating protocol and loading state.
 */
export function useCreateProtocolAndNavigate() {
  const { handleCreateProtocol, loading } = useCreateProtocol();
  const { navigate } = useNavigation();
  const handleCreateProtocolAndNavigate = async (
    name: string,
    workflowId: WorkflowId,
  ) => {
    const updateResult = await handleCreateProtocol(name, workflowId);
    if (updateResult?.protocol) {
      navigate(protocolsRoutes.editProtocol, {
        id: updateResult.protocol.id,
        version: '1',
      });
    }
  };

  return { handleCreateProtocolAndNavigate, loading };
}

/**
 * Creates a copy of the protocol from an existing workflow and navigates
 * to the editing route for that new protocol.
 *
 * @returns Handler for creating protocol and loading state.
 */
export function useCopyProtocolAndNavigate() {
  const { handleCopyProtocol, loading } = useCopyProtocol();
  const { navigate } = useNavigation();
  const handleCopyProtocolAndNavigate = async (
    id: ProtocolId,
    version: ProtocolVersion,
  ) => {
    const updateResult = await handleCopyProtocol(id, version);
    if (updateResult?.protocol) {
      navigate(protocolsRoutes.editProtocol, {
        id: updateResult.protocol.id,
        version: '1',
      });
    }
  };

  return { handleCopyProtocolAndNavigate, loading };
}

/**
 * Creates a new protocol instance from an existing protocol and navigates
 * to the editing route for that new protocol instance.
 *
 * @returns Handler for creating protocol instance and loading state.
 */
export function useCreateProtocolInstanceAndNavigate() {
  const { handleCreateProtocolInstance, loading } = useCreateProtocolInstance();
  const { navigate } = useNavigation();
  const handleCreateProtocolInstanceAndNavigate = async (
    protocolId: ProtocolId,
    protocolVersion: ProtocolVersion,
  ) => {
    const updateResult = await handleCreateProtocolInstance(protocolId, protocolVersion);
    if (updateResult?.protocolInstance) {
      navigate(protocolsRoutes.editProtocolInstance, {
        id: updateResult.protocolInstance.id,
      });
    }
  };

  return { handleCreateProtocolInstanceAndNavigate, loading };
}

/**
 * Publishes a protocol
 *
 * @returns Handler for creating protocol instance and loading state.
 */
export function usePublishProtocol() {
  const [publishProtocol, { loading }] = useMutation(PUBLISH_PROTOCOL);
  const handlePublishProtocol = async (
    id: ProtocolId,
    version: ProtocolVersion,
    isPublic: boolean,
    tagsToAdd?: string[],
  ): Promise<PublishProtocolMutation['publishProtocol']> => {
    const updateResult = await publishProtocol({
      variables: {
        input: {
          id,
          version,
          isPublic,
          tagsToAdd,
        },
      },
    });

    return getResultOrThrow(
      updateResult,
      'Publish protocol',
      data => data.publishProtocol,
    );
  };

  return { handlePublishProtocol, loading };
}

/**
 * Publishes a protocol and navigates
 * to the protocols list.
 *
 * @returns Handler for creating protocol instance and loading state.
 */
export function usePublishProtocolAndNavigate() {
  const { handlePublishProtocol, loading } = usePublishProtocol();
  const { navigate } = useNavigation();
  const handlePublishProtocolAndNavigate = async (
    id: ProtocolId,
    version: ProtocolVersion,
    isPublic: boolean,
    tagsToAdd?: string[],
  ) => {
    const updateResult = await handlePublishProtocol(id, version, isPublic, tagsToAdd);
    if (updateResult?.protocol) {
      navigate(experimentsRoutes.protocols, undefined);
    }
  };

  return { handlePublishProtocolAndNavigate, loading };
}

export type ProtocolUpdate = {
  protocol?: Protocol;
  name?: string;
  shortDescription?: string;
};

/**
 * Delates the protocol
 *
 * @returns Handler for deleting protocol and loading state.
 */
export function useDeleteProtocol() {
  const [deleteProtocol, { loading }] = useMutation<DeleteProtocolMutation>(
    DELETE_PROTOCOL,
    {
      update(cache, { data }) {
        cache.modify({
          fields: {
            protocols(existingProtocols = {}, { readField }) {
              const items = existingProtocols.items || [];

              const updatedItems = items.filter(
                (itemRef: Reference) =>
                  readField('id', itemRef) !== data?.deleteProtocol?.protocol.id,
              );

              return {
                ...existingProtocols,
                items: updatedItems,
              };
            },
          },
        });
      },
      optimisticResponse: variables => {
        const id = variables.input.id;

        return {
          __typename: 'Mutation',
          deleteProtocol: {
            __typename: 'CreateProtocolOutput',
            protocol: {
              __typename: 'Protocol',
              id,
            },
          },
        };
      },
    },
  );

  const handleDeleteProtocol = async (
    id: ProtocolId,
    version: ProtocolVersion,
    editVersion: number,
  ) => {
    await deleteProtocol({
      variables: {
        input: {
          id,
          version,
          editVersion,
        },
      },
    });
  };
  return { handleDeleteProtocol, loading };
}

/**
 * Updates the protocol
 *
 * @returns Handler for updating protocol and loading state.
 */
export function useUpdateProtocol(id: ProtocolId, version: ProtocolVersion) {
  const [updateProtocol, { loading }] = useMutation(UPDATE_PROTOCOL);

  const handleUpdateProtocol = async (editVersion: number, values: ProtocolUpdate) => {
    await updateProtocol({
      variables: {
        input: {
          id,
          version,
          editVersion,
          ...values,
        },
      },
    });
  };
  return { handleUpdateProtocol, loading };
}

/**
 * Updates the workflow
 *
 * @returns Handler for updating workflow and loading state.
 */
export function useUpdateWorkflow() {
  const [updateProtocolWorkflow, { loading }] = useMutation(UPDATE_PROTOCOL_WORKFLOW);

  const handleUpdateWorkflow = async (
    id: WorkflowId,
    version: number,
    workflow: WorkflowBlob,
  ) => {
    await updateProtocolWorkflow({
      variables: {
        input: {
          id,
          version,
          workflow,
        },
      },
    });
  };
  return { handleUpdateWorkflow, loading };
}

type ProtocolInstanceUpdate = {
  name?: string;
  params?: ParameterMap;
};

/**
 * Updates the protocol instance with parameters or name
 *
 * @returns Handler for updating protocol instance and loading state.
 */
export function useUpdateProtocolInstance(id: ProtocolInstanceId) {
  const [updateProtocolInstance, { loading }] = useMutation(UPDATE_PROTOCOL_INSTANCE);

  const handleUpdateProtocolInstance = async (
    editVersion: number,
    opts: ProtocolInstanceUpdate = {},
  ) => {
    const { name, params } = opts;
    const updateResult = await updateProtocolInstance({
      variables: {
        input: {
          id,
          name,
          editVersion,
          params,
        },
      },
    });

    return getResultOrThrow(
      updateResult,
      'Update protocol instance',
      data => data.updateProtocolInstance,
    );
  };

  return { handleUpdateProtocolInstance, loading };
}
