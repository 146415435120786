import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';

import { useProtocolContext } from 'client/app/apps/protocols/context/ProtocolProvider';
import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import { InputStep, InputStepSkeleton } from 'client/app/apps/protocols/InputStep';
import { InputStepList } from 'client/app/apps/protocols/InputStepList';
import { useComplexParameterEditorDialogManager } from 'client/app/apps/protocols/lib/utils';
import {
  OutputStepPreview,
  OutputStepPreviewSkeleton,
} from 'client/app/apps/protocols/OutputStepPreview';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';

type Props = {
  /**
   * navigateBack should enable navigation back to the previous stage of
   * protocol creation
   */
  navigateBack: () => void;
};

export const PersonaliseProtocol = ({ navigateBack }: Props) => {
  // loading of step state is what is important; not whether mutations of
  // protocol or workflow entities have been completed or not. Relying on the
  // latter would cause the skeleton to be shown every time we update the steps
  const { protocolSteps, updateStepInput } = useStepsContext();
  const loading = protocolSteps.length === 0;
  const dialogs = useComplexParameterEditorDialogManager();

  const { displayDescription, updateProtocol } = useProtocolContext();
  useEffect(() => {
    // any time step state is updated by any child component queue a protocol update
    updateProtocol({ protocol: { displayDescription, steps: protocolSteps } });
  }, [displayDescription, protocolSteps, updateProtocol]);

  return (
    <Wrapper>
      <InputStepList isDraggable alwaysExpandList />
      <InputsAndOutputsWrapper>
        {loading ? (
          <>
            <InputStepSkeleton />
            <OutputStepPreviewSkeleton />
          </>
        ) : (
          <>
            <InputStep updateDescription={updateStepInput} />
            <OutputStepPreview
              noOutputCallToAction={
                <Button variant="secondary" onClick={navigateBack}>
                  + ADD OUTPUT
                </Button>
              }
            />
          </>
        )}
      </InputsAndOutputsWrapper>
      {dialogs}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplate: `
      "list inputsAndOutputs inputsAndOutputs" minmax(400px, 1200px)
      / auto 1fr 1fr`,
  padding: theme.spacing(8),
  gap: theme.spacing(7),
  height: '100%',
  overflow: 'auto',
  backgroundColor: Colors.GREY_10,
  position: 'relative',
}));

const InputsAndOutputsWrapper = styled('div')({
  gridArea: 'inputsAndOutputs',
  display: 'flex',
  overflowX: 'auto',
  zIndex: 1,
});
